import React from "react";

export default class Epic extends React.Component {
  componentDidMount() {
    window.location.replace("https://store.epicgames.com/p/metalstorm");
  }

  render() {
    return <div />;
  }
}
